const disabledControls: Record<string, string[]> = {
  view: [
    'annotate',
    'ribbons',
    'selectToolButton',
    'toggleNotesButton',
    'toolsHeader',
    'contextMenuPopup',
    'annotationCommentButton',
  ],
  edit: [],
};

export const getViewerInitConfig = (mode: string) => {
  return disabledControls[mode];
};

// https://docs.apryse.com/api/web/Core.DocumentViewer.html#FitMode
export const ViewerFitMode = {
  FITWIDTH: 'FitWidth',
  FITPAGE: 'FitPage',
  ZOOM: 'Zoom',
};
