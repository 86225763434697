import {
  ActionIcon,
  Box,
  Flex,
  Grid,
  Group,
  Modal,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconGitCompare, IconStar } from '@tabler/icons-react';
import FileViewer from 'Components/file-viewer/FileViewer';
import { DocumentSnapshotType } from 'Types/docTypes';
import { transformDateString } from 'Utils/transform';

import { DeleteAction } from './SelectSnapshotVersions';

type CompareSnapshotsModalProps = {
  snapshotsToCompare: DocumentSnapshotType[] | [];
  opened: boolean;
  close: () => void;
  refreshSnapshotVersions?: () => void;
};

const CompareSnapshotModal: React.FC<CompareSnapshotsModalProps> = ({
  snapshotsToCompare,
  opened,
  close,
  refreshSnapshotVersions,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={close}
      size="100%"
      radius={0}
      transitionProps={{ transition: 'fade', duration: 200 }}
      title={
        <Group gap="xs" align="center">
          <IconGitCompare stroke={1.5} />
          <Text>Compare Snapshots</Text>
        </Group>
      }
    >
      <Grid gutter="sm">
        {snapshotsToCompare &&
          snapshotsToCompare.map((snapshot, index) => (
            <Grid.Col key={index} span={6}>
              <Flex w="100%" p="sm" justify="space-between">
                <Box maw="70%">
                  <Tooltip label={snapshot.file_original_name}>
                    <Text truncate="end" size="sm">
                      {transformDateString(snapshot.created_at, true)}
                    </Text>
                  </Tooltip>
                </Box>
                {index === 1 ? (
                  <Group gap={5}>
                    <Tooltip label="Mark Latest">
                      <ActionIcon variant="transparent">
                        <IconStar stroke={1.5} />
                      </ActionIcon>
                    </Tooltip>
                    <DeleteAction
                      optionValue={snapshot.id.toString()}
                      callbackOnComplete={() => {
                        if (refreshSnapshotVersions) refreshSnapshotVersions();
                        close();
                      }}
                    />
                  </Group>
                ) : null}
              </Flex>
              {snapshot && <FileViewer fileUrl={snapshot.file} />}
            </Grid.Col>
          ))}
      </Grid>
    </Modal>
  );
};

export default CompareSnapshotModal;
