import { request, generateCRUDOps, API_PATH } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

const SnapshotService = {
  ...generateCRUDOps(pathPrefix, 'snapshots'),
  getSnapshotsByDocId: async (id: number) => {
    return request({
      url: `${API_PATH.REVIEWS}documents/${id}/snapshots/`,
      method: 'get',
    });
  },
};

export default SnapshotService;
