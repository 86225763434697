import React from 'react';

import {
  Collapse,
  Stack,
  Spoiler,
  Text,
  Group,
  Select,
  Tooltip,
} from '@mantine/core';
import ruleInprogressIcon from 'Assets/rule-in-progress.svg';
import Textarea from 'Components/text-area/Textarea';
import { RuleEvalStatus } from 'Constants/index';
import useRuleStatus from 'Src/hooks/useRuleStatus';
import { RuleEvalType } from 'Types/ruleTypes';

import DisplayRetriggerAction from './RetriggerAction';

interface RuleDetailsProps {
  cardOpened: boolean;
  currentRuleEval: RuleEvalType;
  onSelectStatus: (status: string | null) => void;
  confirmRetriggerRule: () => void;
  reTriggerRule: () => void;
  openStatusPrompt: boolean;
  closeConfirmRetriggerModal: () => void;
}

const DisplayRuleInProgress = () => {
  return (
    <Tooltip label="Rule execution is in prorgess">
      <img src={ruleInprogressIcon} width="20px" height="20px" />
    </Tooltip>
  );
};

const RuleDetails: React.FC<RuleDetailsProps> = ({
  cardOpened,
  currentRuleEval,
  onSelectStatus,
  confirmRetriggerRule,
  reTriggerRule,
  openStatusPrompt,
  closeConfirmRetriggerModal,
}) => {
  const {
    isRuleInProgress,
    showSelectStatusManually,
    isRuleManuallyOverridden,
    rationales,
  } = useRuleStatus({ ruleEval: currentRuleEval });

  return (
    <Collapse in={cardOpened} ml="xl">
      <Stack ml="sm" mt="xs" gap="sm">
        <Spoiler
          maxHeight={50}
          showLabel={
            <Text c="blue" size="sm">
              Show more
            </Text>
          }
          hideLabel={
            <Text c="blue" size="sm">
              Show less
            </Text>
          }
          fz={16}
        >
          <Text size="xs">{currentRuleEval.rule.description}</Text>
        </Spoiler>
        {rationales && rationales.length > 0 && (
          <Textarea
            value={rationales.join(' ')}
            disabled
            variant="filled"
            showAsComment
            maxRows={3}
          />
        )}
        <Group justify="space-between">
          <Stack gap="6" w="25%">
            <Select
              size="xs"
              placeholder="Select status"
              onChange={onSelectStatus}
              data={Object.keys(RuleEvalStatus).map((status) => {
                const key = status as keyof typeof RuleEvalStatus;
                return {
                  value: key,
                  label: RuleEvalStatus[key],
                };
              })}
              value={currentRuleEval.status?.id.toString()}
            />
            {showSelectStatusManually && (
              <Text size="xs" c="dimmed">
                Select Status manually
              </Text>
            )}
            {!showSelectStatusManually && isRuleManuallyOverridden && (
              <Text size="xs" c="dimmed">
                Manually selected
              </Text>
            )}
          </Stack>
          {isRuleInProgress && <DisplayRuleInProgress />}
          {!isRuleInProgress && (
            <DisplayRetriggerAction
              open={confirmRetriggerRule}
              reTriggerRule={reTriggerRule}
              openStatusPrompt={openStatusPrompt}
              closeConfirmRetriggerModal={closeConfirmRetriggerModal}
            />
          )}
        </Group>
      </Stack>
    </Collapse>
  );
};

export default RuleDetails;
