import { DataTableColumn } from 'mantine-datatable';

import { Badge, Box, Text } from '@mantine/core';
import DataTable from 'Components/data-table/DataTable';
import { RuleType } from 'Src/types/ruleTypes';
import { transformDateString } from 'Src/utils/transform';

type ListRulesProps = {
  rules: RuleType[];
  openFilledDrawer: (id: number) => void;
  onPageChange: (page: number) => void;
  loadingRules: boolean;
  totalRules: number;
  page: number;
};

const ListRules: React.FC<ListRulesProps> = ({
  rules = [],
  openFilledDrawer,
  onPageChange,
  loadingRules,
  totalRules,
  page,
}) => {
  const columns: DataTableColumn<RuleType>[] = [
    {
      accessor: 'title',
      title: 'Title',
      width: '40%',
      render: (rule) => (
        <Text
          size="sm"
          td="underline"
          onClick={() => {
            if (rule && rule.id) {
              openFilledDrawer(rule.id);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          {rule?.title}
        </Text>
      ),
    },
    {
      accessor: 'is_active',
      title: 'Status',
      render: (rule) => (
        <Badge color={rule.is_active ? 'green' : 'gray'}>
          {rule.is_active ? 'Active' : 'In-Active'}
        </Badge>
      ),
    },
    {
      accessor: 'updated_by',
      title: 'Updated By',
      render: (rule) => rule?.updated_by?.first_name,
    },
    {
      accessor: 'updated_at',
      title: 'Updated At',
      render: (rule) => transformDateString(rule?.updated_at),
    },
    {
      accessor: 'created_at',
      title: 'Created At',
      render: (rule) => transformDateString(rule?.created_at || ''),
    },
  ];

  return (
    <Box>
      <DataTable
        withTableBorder={false}
        borderRadius="md"
        height="80vh"
        striped
        fetching={loadingRules}
        highlightOnHover
        verticalSpacing="xs"
        horizontalSpacing="lg"
        records={rules}
        columns={columns}
        totalRecords={totalRules}
        paginationActiveBackgroundColor="grape"
        recordsPerPage={100}
        page={page}
        onPageChange={(currentPage) => {
          onPageChange(currentPage);
        }}
      />
    </Box>
  );
};

export default ListRules;
