import { Box, Divider, Tabs, Text } from '@mantine/core';
import { DocumentDataType } from 'Types/docTypes';

type BatchListProps = {
  documentData: DocumentDataType | null;
};

const BatchList: React.FC<BatchListProps> = ({ documentData }) => {
  console.log(documentData, 'documentData');

  // const pdfFiles = [
  //   'invoice_january.pdf',
  //   'report_2020.pdf',
  //   'user_guide.pdf',
  //   'meeting_minutes_0301.pdf',
  //   'financial_statement_q1.pdf',
  //   'project_plan.pdf',
  // ];

  return (
    <Box p="lg">
      {/* <Title order={3}>Batch Name</Title> */}
      <Text size="sm" mt={10}>
        This section will be used to navigate through different files belonging
        to a same batch
      </Text>
      <Divider my={20} />
      <Tabs defaultValue="file">
        <Tabs.List>
          <Tabs.Tab value="file">File</Tabs.Tab>
          <Tabs.Tab value="info">Info</Tabs.Tab>
        </Tabs.List>

        {/* <Tabs.Panel value="file">
          {pdfFiles.map((file, index) => (
            <Box className={classes.fileInfoBox} key={index} py={10}>
              <Grid>
                <Grid.Col span={10}>
                  <Grid>
                    <Grid.Col span={2}>
                      <IconFile size={16} />
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Text truncate="end" size="sm">
                        {file}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={2}>
                  <ActionIcon
                    size="xs"
                    variant="transparent"
                    aria-label="Settings"
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            </Box>
          ))}
        </Tabs.Panel> */}
        <Tabs.Panel value="info">Messages tab content</Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default BatchList;
