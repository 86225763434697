import { useState } from 'react';

import { Box, Group, Text, Card, LoadingOverlay, Divider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import RuleService from 'Api/ruleService';
import IconDocument from 'Components/icons/IconDocument';
import { RuleEvalType } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import Details from './Details';
import Header from './Header';

type RuleEvalCardProps = {
  ruleEval: RuleEvalType;
  refreshAssessmentDetail: () => void;
};

const RuleUnavailable = () => {
  return (
    <Card padding="0" withBorder={false}>
      <Group>
        <IconDocument color={'gray'} />
        <Text size="sm">Rule Unavailable</Text>
      </Group>
      <Divider mt="md" />
    </Card>
  );
};

const RuleEvalCard: React.FC<RuleEvalCardProps> = ({
  ruleEval,
  refreshAssessmentDetail,
}) => {
  const [ruleEvalLoading, setRuleEvalLoading] = useState<boolean>(false);
  const [
    openStatusPrompt,
    { close: closeConfirmRetriggerModal, open: confirmRetriggerRule },
  ] = useDisclosure(false);
  const [cardOpened, { toggle: toggleCard }] = useDisclosure(false);

  const onSelectStatus = async (value: string | null) => {
    if (!ruleEval || !value) return;
    if (ruleEval.toString() === value) return;
    setRuleEvalLoading(true);
    try {
      const { data } = await RuleService.updateRuleEvalStatus(
        ruleEval.id,
        parseInt(value)
      );
      if (data) {
        showInfoNotification(
          'Rule Updated',
          'Rule evaluation status updated successfully'
        );
        refreshAssessmentDetail();
      } else {
        throw new Error('Error while updating rule evaluation status');
      }
    } catch (e) {
      showErrorNotification(
        'Something went wrong while update rule evaluation status'
      );
    }
    setRuleEvalLoading(false);
  };

  const reTriggerRule = async () => {
    closeConfirmRetriggerModal();
    if (!ruleEval) return;
    setRuleEvalLoading(true);
    const { data } = await RuleService.triggerRuleEvaluation(
      ruleEval?.id,
      true
    );
    if (data) {
      refreshAssessmentDetail();
    }
    setRuleEvalLoading(false);
  };

  return (
    <Box key={ruleEval?.id} pos="relative">
      <LoadingOverlay
        visible={ruleEvalLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ color: 'pink', type: 'bars' }}
      />
      {ruleEval ? (
        <Card padding="0" withBorder={false}>
          <Header
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            toggleCard={toggleCard}
            cardOpened={cardOpened}
          />
          <Details
            cardOpened={cardOpened}
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            confirmRetriggerRule={confirmRetriggerRule}
            reTriggerRule={reTriggerRule}
            openStatusPrompt={openStatusPrompt}
            closeConfirmRetriggerModal={closeConfirmRetriggerModal}
          />
          <Divider mt="sm" />
        </Card>
      ) : (
        <RuleUnavailable />
      )}
    </Box>
  );
};

export default RuleEvalCard;
