import React, { useCallback, useEffect, useRef } from 'react';

import { Text, Accordion } from '@mantine/core';
import { Clusters, TWord, Words } from 'Types/extractorTypes';

type DisplayClustersProps = {
  clusterKeys: string[];
  pageData: Clusters;
  highlightText: (
    coordinates: {
      x_left: number;
      x_right: number;
      y_top: number;
      y_bottom: number;
      page: number;
    } | null
  ) => void;
  pageNumber: number;
};

const DisplayClusters: React.FC<DisplayClustersProps> = ({
  clusterKeys,
  pageData,
  highlightText,
  pageNumber,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const updateHighlight = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      highlightText(null);
      return;
    }

    const range = selection.getRangeAt(0);
    const startElement = range.startContainer.parentElement;
    const endElement = range.endContainer.parentElement;

    if (!startElement || !endElement) return;

    const startWord = getWordFromElement(startElement);
    const endWord = getWordFromElement(endElement);

    if (startWord && endWord) {
      const startBox = startWord.bounding_box;
      const endBox = endWord.bounding_box;
      if (startBox && endBox) {
        highlightText({
          x_left: Math.min(startBox.x_left, endBox.x_left),
          y_top: Math.min(startBox.y_top, endBox.y_top),
          x_right: Math.max(startBox.x_right, endBox.x_right),
          y_bottom: Math.max(startBox.y_bottom, endBox.y_bottom),
          page: pageNumber,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightText, pageNumber]);

  const getWordFromElement = (element: Element): TWord | null => {
    const wordIndex = element.getAttribute('data-word-index');
    const lineElement = element.closest('[data-line-index]');
    if (wordIndex && lineElement) {
      const clusterKey = lineElement.getAttribute('data-cluster-key');
      const lineIndex = lineElement.getAttribute('data-line-index');
      const elementPage = lineElement.getAttribute('data-page');
      if (
        clusterKey &&
        lineIndex &&
        elementPage &&
        parseInt(elementPage) === pageNumber
      ) {
        const cluster = pageData[clusterKey];
        if (cluster && Array.isArray(cluster)) {
          const line = cluster[parseInt(lineIndex)];
          if (line && Array.isArray(line)) {
            return line[parseInt(wordIndex)] || null;
          }
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      updateHighlight();
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [updateHighlight]);

  return (
    <div ref={containerRef}>
      <Accordion className="accordion-container">
        {clusterKeys.map((clusterKey) => {
          const curCluster = pageData[clusterKey] as Clusters[string];
          return (
            <Accordion.Item key={clusterKey} value={clusterKey}>
              <Accordion.Control>
                <Text c="#757575" size="sm">
                  Cluster {clusterKey}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                {curCluster.map((lineItem: Words, lineIndex: number) => (
                  <Text
                    key={lineIndex}
                    data-cluster-key={clusterKey}
                    data-line-index={lineIndex}
                    data-page={pageNumber}
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      cursor: 'text',
                      lineHeight: 'normal',
                    }}
                    mb="xs"
                  >
                    {lineItem.map((item: TWord, wordIndex: number) => (
                      <span
                        key={wordIndex}
                        data-word-index={wordIndex}
                        style={{
                          fontWeight: item.font_style?.font_weight,
                          fontFamily: item.font_style?.font_family,
                          fontSize: item.font_style?.font_size
                            ? `${Math.min(Number(item.font_style.font_size) * 2, 16)}px`
                            : 'inherit',
                        }}
                      >
                        {item.text}
                        {wordIndex < lineItem.length - 1 ? ' ' : ''}
                      </span>
                    ))}
                  </Text>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default DisplayClusters;
