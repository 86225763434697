import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import { Flex, Text, Card, Box, Tabs } from '@mantine/core';
import { AssessmentTabs } from 'Constants/index';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { DocumentDataType } from 'Types/docTypes';
import { TextCoords } from 'Types/fileViewerTypes';

import classes from './../Assessment.module.scss';
import AssessmentTab from './assessment-tab/AssessmentTab';
import RuleSetupTab from './rule-setup-tab/RuleSetup';

type TabsTypes = {
  documentData: DocumentDataType;
  assessmentData: AssessmentDetailType | null;
  setTextCoords: Dispatch<SetStateAction<TextCoords | null>>;
  reRunAssessment: (data: AssessmentDetailType) => void;
  fetchDocument: () => Promise<DocumentDataType | null>;
  setAssessmentData: Dispatch<SetStateAction<AssessmentDetailType | null>>;
};

const TabsContainer: React.FC<TabsTypes> = ({
  documentData,
  assessmentData,
  setTextCoords,
  reRunAssessment,
  fetchDocument,
  setAssessmentData,
}) => {
  const [activeTab, setActiveTab] = useState<string>('assessment');

  useEffect(() => {
    if (assessmentData && assessmentData.report_id && assessmentData.id) {
      setActiveTab(AssessmentTabs.ASSESSMENT_TAB);
    } else {
      setActiveTab(AssessmentTabs.SETUP_TAB);
    }
  }, [assessmentData]);

  const tabsData = [
    { label: 'Assessment', value: 'assessment' },
    { label: 'Rule Setup', value: 'setup' },
  ];

  const handleTabChange = (value: string | null) => {
    if (value !== null) {
      setActiveTab(value);
    }
  };

  return (
    <Tabs
      variant="pills"
      defaultValue="setup"
      value={activeTab}
      onChange={handleTabChange}
      style={{ width: '100%' }}
      keepMounted={false}
    >
      <Flex direction="column" w={'100%'} h={'100%'}>
        <Box
          w={'100%'}
          pos={'sticky'}
          top={'0'}
          bg="white"
          style={{ zIndex: 99 }}
        >
          <Card
            p={8}
            withBorder
            radius={0}
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <Tabs.List>
              {tabsData.map((tab, index) => {
                return (
                  <Tabs.Tab
                    color="white"
                    value={tab.value}
                    className={classes.tab}
                    key={index}
                  >
                    <Text
                      c="black"
                      size="sm"
                      fw={activeTab === tab.value ? 700 : 500}
                    >
                      {tab.label}
                    </Text>
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
          </Card>
          <Flex flex={1}>
            <Tabs.Panel value="assessment" w="100%">
              <AssessmentTab
                documentData={documentData}
                assessmentData={assessmentData}
                setAssessmentData={setAssessmentData}
                reRunAssessment={reRunAssessment}
                fetchDocument={fetchDocument}
                setTextCoords={setTextCoords}
                changeMainTab={(tab) => setActiveTab(tab)}
              />
            </Tabs.Panel>
            <Tabs.Panel value="setup" w="100%">
              <RuleSetupTab
                assessmentData={assessmentData}
                changeActiveTab={(tab) => setActiveTab(tab)}
                documentData={documentData}
                setAssessmentData={setAssessmentData}
              />
            </Tabs.Panel>
          </Flex>
        </Box>
      </Flex>
    </Tabs>
  );
};

export default TabsContainer;
