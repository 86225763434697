import { useRef } from 'react';

import { Group, Anchor, Text, Button, Menu, Box, Tooltip } from '@mantine/core';
import { FileButton } from '@mantine/core';
import {
  IconFileText,
  IconCirclePlus,
  IconDotsVertical,
} from '@tabler/icons-react';
import UploadFileButton from 'Components/upload-file-button/UploadFileButton';
import { transformDateString } from 'Utils/transform';

import {
  FilenameColumnProps,
  LastActivityColumnProps,
  SnapshotsColumnProps,
  CreatedAtColumnProps,
  ActionsColumnProps,
} from './types';

export const FilenameColumn: React.FC<FilenameColumnProps> = ({
  element,
  setPreviewDocumentDetails,
  handlePreviewDocumentDrawer,
}) => {
  if (!element) return null;

  return (
    <Group wrap={'nowrap'}>
      <IconFileText style={{ flexShrink: 0 }} />
      <Anchor
        href={`#preview-${element.latest_snapshot?.id || element.id}`}
        onClick={() => {
          setPreviewDocumentDetails(element);
          handlePreviewDocumentDrawer.open();
        }}
        c="dark"
        underline="always"
        size="sm"
        w={'100%'}
      >
        <Tooltip label={element.filename}>
          <Text size="sm" truncate="end" w={'90%'}>
            {element.filename}
          </Text>
        </Tooltip>
      </Anchor>
    </Group>
  );
};

export const LastActivityColumn: React.FC<LastActivityColumnProps> = ({
  latestSnapshot,
}) => {
  return (
    <Text size="sm">{transformDateString(latestSnapshot.updated_at)}</Text>
  );
};

export const SnapshotsColumn: React.FC<SnapshotsColumnProps> = ({
  document,
  onFileSelect,
}) => {
  const { total_snapshots } = document;

  return (
    <Group justify="left">
      {total_snapshots || '0'}
      <UploadFileButton
        onUpload={(file: File | null) => onFileSelect(file, document)}
      >
        <IconCirclePlus cursor={'pointer'} color="#CD307D" />
      </UploadFileButton>
    </Group>
  );
};

export const CreatedAtColumn: React.FC<CreatedAtColumnProps> = ({
  latestSnapshot,
}) => {
  return (
    <Text size="sm">{transformDateString(latestSnapshot.created_at)}</Text>
  );
};

export const ActionsColumn: React.FC<ActionsColumnProps> = ({
  document,
  navigate,
  onFileSelect,
  deleteDocument,
  fetchDocuments,
}) => {
  const fileInputRef = useRef<HTMLButtonElement>(null);

  const handleDelete = async () => {
    await deleteDocument(document.id);
    fetchDocuments();
  };

  return (
    <Group justify="flex-end">
      <Menu position="right-start" offset={2} withArrow arrowPosition="center">
        <Button
          size="xs"
          variant="outline"
          onClick={() =>
            navigate(`/assessment/${document.batch_id}/${document.id}`)
          }
        >
          Assessment
        </Button>

        <Menu.Target>
          <IconDotsVertical cursor={'pointer'} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={(e) => {
              e.preventDefault();
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            <Text size="xs">Add</Text>
          </Menu.Item>
          <Menu.Item component="a" onClick={handleDelete}>
            <Text size="xs">Delete</Text>
          </Menu.Item>
        </Menu.Dropdown>
        <Box display="none">
          <FileButton
            onChange={(file: File | null) => {
              if (file) {
                onFileSelect(file, document);
              }
            }}
          >
            {(props) => (
              <Button ref={fileInputRef} {...props}>
                Upload File
              </Button>
            )}
          </FileButton>
        </Box>
      </Menu>
    </Group>
  );
};
