import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

import { Box, Flex, Text, Button } from '@mantine/core';
import AssessmentService from 'Api/assessmentService';
import RuleService from 'Api/ruleService';
import { RULE_TAG_KEY } from 'Src/constants';
import useLoading from 'Src/hooks/useLoading';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { FiltersType } from 'Types/commonTypes';
import { DocumentDataType } from 'Types/docTypes';
import {
  RuleEvalType,
  RuleType,
  RuleIdWithResetStatus,
  RuleWithEvalType,
} from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
  showLoadingNotification,
} from 'Utils/notifications';

import ListRules from './ListRules';
import SearchWithFilters from './SearchWithFilters';

interface RuleSetupTab {
  documentData: DocumentDataType;
  assessmentData: AssessmentDetailType | null;
  changeActiveTab: (tab: string) => void;
  setAssessmentData: Dispatch<SetStateAction<AssessmentDetailType | null>>;
}

const anyNewRuleSelected = (selectedRules: RuleType[], ruleEvals: number[]) => {
  if (!selectedRules.length) return false;
  const ruleIds = selectedRules.map((item: RuleType) => item.id);
  return ruleIds.some((id) => !ruleEvals.includes(id));
};

const RuleSetupTab: React.FC<RuleSetupTab> = ({
  assessmentData,
  changeActiveTab,
  documentData,
  setAssessmentData,
}) => {
  const [loading, handleLoading] = useLoading(false);
  const [runningAssessment, handleRunningAssessment] = useLoading(false);
  const [allRules, setAllRules] = useState<RuleType[]>([]);
  const [filteredRules, setFilteredRules] = useState<RuleType[]>([]);
  const [selectedRules, setSelectedRules] = useState<RuleType[]>([]);
  const [ruleEvals, setRuleEvals] = useState<number[]>([]);
  const assessmentExists = Boolean(
    assessmentData?.report_id && assessmentData?.id
  );
  const submitButtonLabel = assessmentExists
    ? 'Add and Re-run Assessment'
    : 'Add and Run assessment';
  const enableAssessmentButton = useMemo(
    () => anyNewRuleSelected(selectedRules, ruleEvals),
    [selectedRules, ruleEvals]
  );

  const onRuleSelect = useCallback(
    (ruleConfig: {
      addAll?: boolean;
      removeAll?: boolean;
      ruleToAdd?: object;
    }) => {
      if (ruleConfig.addAll) setSelectedRules(filteredRules);
      else if (ruleConfig.removeAll)
        setSelectedRules(
          selectedRules.filter((item: RuleWithEvalType) => item.hasRuleEval)
        );
      else {
        const currentRule = ruleConfig.ruleToAdd as RuleType;
        if (selectedRules.map((item) => item.id).includes(currentRule.id))
          setSelectedRules(
            selectedRules.filter((rule) => rule.id !== currentRule.id)
          );
        else setSelectedRules([...selectedRules, currentRule]);
      }
    },
    [filteredRules, selectedRules]
  );

  const saveRulesAndCreateAssessment = async () => {
    showLoadingNotification('Creating Assessment', 'Please wait...');
    try {
      handleRunningAssessment.start();
      if (documentData === null) return;
      const rulesToSend: RuleIdWithResetStatus = selectedRules.reduce(
        (acc, rule) => {
          acc[rule.id] = { reset_status: true };
          return acc;
        },
        {} as RuleIdWithResetStatus
      );
      const { batch_id: batchId, id: docId, latest_snapshot } = documentData;
      const snapshotIdToUse = latest_snapshot.id;
      const { data } = await AssessmentService.createAssessments({
        batch_id: batchId,
        document_id: docId,
        document_snapshot_id: snapshotIdToUse,
        reparse: false,
        rules:
          rulesToSend ||
          filteredRules.map((item) => ({ [item.id]: { reset_status: true } })),
      });
      if (data && data.id) {
        setAssessmentData(data);
      }
      changeActiveTab('assessment');
    } catch (e: any) {
      showErrorNotification(e.message);
    } finally {
      handleRunningAssessment.stop();
    }
  };

  const runAssessment = async () => {
    try {
      showInfoNotification(
        'Re running the assessment',
        'Evaluation will be updated soon...'
      );
      handleRunningAssessment.start();
      if (!assessmentData) return;
      const rulesToSend: RuleIdWithResetStatus = selectedRules.reduce(
        (acc, rule) => {
          acc[rule.id] = { reset_status: true };
          return acc;
        },
        {} as RuleIdWithResetStatus
      );
      const { data } = await AssessmentService.reinitializePolicyEvals(
        assessmentData?.id,
        rulesToSend
      );
      if (data && data.id) {
        setAssessmentData(data);
        changeActiveTab('assessment');
      } else {
        throw new Error('Something went wrong while re-running assessment');
      }
    } catch (e) {
      showErrorNotification('Something went wrong while re-running assessment');
    } finally {
      handleRunningAssessment.stop();
    }
  };

  const searchRule = async (text: string) => {
    if (!text || text.length == 0) {
      fetchRules();
      return;
    }
    handleLoading.start();
    try {
      const response = await RuleService.searchRule(text);
      if (response) {
        updateRulesWithEvaluationsStatus(response.data || [], ruleEvals);
      }
    } catch (e) {
      showErrorNotification('Something went wrong while performing search');
    } finally {
      handleLoading.stop();
    }
  };

  const updateRulesWithEvaluationsStatus = (
    newRules: RuleType[],
    currentRuleEvals: number[]
  ) => {
    const selectedRulesIds = selectedRules.map((i) => i.id);
    const alNewlRules = newRules.map((item: RuleType) => ({
      ...item,
      hasRuleEval: currentRuleEvals.includes(item.id),
    }));
    setFilteredRules(
      alNewlRules.sort((a, b) => {
        return a.hasRuleEval === b.hasRuleEval ? 0 : a.hasRuleEval ? 1 : -1;
      })
    );
    const addedRules = alNewlRules.filter(
      (item: RuleWithEvalType) =>
        item.hasRuleEval || selectedRulesIds.includes(item.id)
    );
    const newSelectedRules = addedRules.filter(
      (item) => !selectedRulesIds.includes(item.id)
    );
    setSelectedRules((prevSelectedRules) => [
      ...prevSelectedRules,
      ...newSelectedRules,
    ]);
  };

  const fetchRules = async (filters?: FiltersType) => {
    handleLoading.start();
    try {
      let currentRuleEvals = [];
      if (assessmentData && assessmentData.report_id && assessmentData.id) {
        const data = await fetchAssessmentDetail();
        currentRuleEvals = data.rule_evals;
        if (currentRuleEvals.length > 0) {
          currentRuleEvals = currentRuleEvals.map(
            (item: RuleEvalType) => item.rule.id
          );
        }
      }
      setRuleEvals(currentRuleEvals);
      let transformedFilters = filters;
      if (filters) {
        if (
          Object.keys(filters).includes(RULE_TAG_KEY) &&
          filters[RULE_TAG_KEY]
        ) {
          transformedFilters = {
            ...filters,
            [RULE_TAG_KEY]: (filters[RULE_TAG_KEY] as string[]).map((tag) =>
              tag === 'All Rules' ? '' : tag
            ),
          };
        }
      }
      const response = await RuleService.getRules({
        filters: transformedFilters,
      });
      if (response && response.data) {
        const { results = [] } = response.data;
        if (allRules.length === 0) {
          setAllRules(results);
        }
        updateRulesWithEvaluationsStatus(results, currentRuleEvals);
      }
    } catch (e) {
      showErrorNotification('Something went wrong while fetching rules');
    } finally {
      handleLoading.stop();
    }
  };

  const fetchAssessmentDetail = async () => {
    try {
      const assessmentId = assessmentData?.id;
      if (!assessmentId)
        return showErrorNotification('Invalid Assessment data');
      const response = await AssessmentService.getAssessmentsById(assessmentId);
      if (response) {
        const { data } = response;
        if (data.report) {
          return data.report;
        }
      } else {
        showErrorNotification(
          'Something went wrong while fetching assessment detail'
        );
      }
    } catch (error) {
      showErrorNotification(
        'Something went wrong while fetching assessment detail'
      );
    }
  };

  useEffect(() => {
    fetchRules();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Flex w={'100%'} direction="column" mih="85vh">
      <Flex flex={1} pos="relative" direction="column">
        <Flex
          pt={24}
          pb={12}
          px={30}
          direction="column"
          pos="sticky"
          top="0"
          style={{ zIndex: 99 }}
          bg="white"
        >
          <Text mb={12} size="lg" fw={500}>
            Select rules to run assessment
          </Text>
          <SearchWithFilters
            onRuleSelect={onRuleSelect}
            noOfSelectedRules={selectedRules.length}
            rules={filteredRules}
            allRules={allRules}
            fetchRules={fetchRules}
            searchRule={searchRule}
          />
        </Flex>
        <Box px={30} style={{ transition: 'transform 0.5s ease-in' }}>
          <ListRules
            loading={loading}
            rules={filteredRules}
            onRuleSelect={onRuleSelect}
            selectedRules={selectedRules}
          />
        </Box>
      </Flex>
      <Flex
        pt={24}
        px={30}
        w="100%"
        p={16}
        bottom={'0'}
        bg="white"
        pos="sticky"
      >
        <Button
          color="#091A29"
          size="lg"
          fullWidth
          onClick={() =>
            assessmentExists ? runAssessment() : saveRulesAndCreateAssessment()
          }
          disabled={!enableAssessmentButton}
          loading={runningAssessment}
        >
          <Text size="sm" fw={700}>
            {submitButtonLabel}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default RuleSetupTab;
