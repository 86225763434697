import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mantine/core';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import useSnapshotAnnotations from 'Src/hooks/useSnapshotAnnotations';
import useTextHighlight from 'Src/hooks/useTextHighlight';
import { FileViwerMode, AnnotationConfigTypes } from 'Types/fileViewerTypes';
import { showErrorNotification } from 'Utils/notifications';

import { getViewerInitConfig, ViewerFitMode } from './fileViewerHelper';
import { TextCoords } from 'Types/fileViewerTypes';

interface PdfViewerProps {
  fileUrl: string;
  enabledControls?: string[];
  disabledControls?: string[];
  onInstanceReady?: (instance: WebViewerInstance) => void;
  searchText?: string | null;
  textCoords?: TextCoords | null;
  mode?: FileViwerMode;
  height?: string;
  annotationConfig?: AnnotationConfigTypes;
}

const FileViewer: React.FC<PdfViewerProps> = ({
  fileUrl,
  onInstanceReady,
  textCoords,
  mode = FileViwerMode.VIEW,
  height = '100vh',
  annotationConfig,
}) => {
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [webViewerInstance, setWebViewerInstance] =
    useState<WebViewerInstance | null>(null);

  /* Crud for annotations based on `annotationConfig` */
  useSnapshotAnnotations({
    instance: webViewerInstance,
    annotationConfig,
    mode,
  });

  // Highlight text in the document based on provided coordinates
  useTextHighlight(webViewerInstance, textCoords);

  const initializeInstance = (instance: WebViewerInstance) => {
    const { UI } = instance;
    const { documentViewer } = instance.Core;
    UI.addEventListener(instance.UI.Events.VIEWER_LOADED, () => {
      UI.setFitMode(ViewerFitMode.FITWIDTH);
    });
    documentViewer.addEventListener('documentLoaded', () => {
      // call methods relating to the loaded document
      setWebViewerInstance(instance);
      if (onInstanceReady) {
        // we can define custom logic on callback
        onInstanceReady(instance);
      }
    });
    instance.UI.loadDocument(fileUrl);
  };

  const createWebViewerInstance = async () => {
    if (!viewerRef.current) return;
    try {
      const instance = await WebViewer(
        {
          path: '/webviewer',
          licenseKey: import.meta.env.VITE_APRYSE_LICENSE_KEY,
          disabledElements: getViewerInitConfig(mode),
        },
        viewerRef.current
      );
      initializeInstance(instance);
    } catch (e) {
      showErrorNotification(
        'Unable to load the document viewer. Please reload the page or try again later.'
      );
    }
  };

  // Creates or reuse existing WebViewer instance
  useEffect(() => {
    if (webViewerInstance) {
      initializeInstance(webViewerInstance);
      return;
    }
    if (viewerRef.current) {
      createWebViewerInstance();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (fileUrl && webViewerInstance) {
      webViewerInstance.UI.loadDocument(fileUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fileUrl]);

  return (
    <Box className="pdf-viewer-container" h={height}>
      <div style={{ height }} ref={viewerRef}></div>
    </Box>
  );
};

export default FileViewer;
