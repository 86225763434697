import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from 'Api/authService';
import { LoginCredentials } from 'Types/authTypes';
import { UserType } from 'Types/userTypes';
import { getUserInfo } from 'Utils/transform';

type Tenant = {
  id: number;
  name: string;
  is_active: boolean;
  slug: string;
};

export type User = {
  email: string;
  tenants?: Tenant[];
  expiry: string;
  token: string;
  currentTenant?: number;
} & Pick<UserType, 'first_name' | 'last_name'>;

export type AuthState = {
  isAuthenticated: boolean;
  user: User | null;
  isLoading?: boolean;
  error?: string | null;
};

export type AuthPayload = {
  expiry: string;
  token: string;
  email?: string;
  tenant?: number | undefined;
};

const initialState: AuthState = {
  isAuthenticated: !!getUserInfo()?.token,
  user: getUserInfo(),
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (data: LoginCredentials) => {
    try {
      const response = await AuthService.login(data);
      if (response && response.data) {
        return response.data;
      }
      throw new Error('Login failed');
    } catch (error) {
      console.error(error);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('user');
    },
    addTenant: (state, action: PayloadAction<{ tenantId: number }>) => {
      const { tenantId } = action.payload;
      if (tenantId && state.user) {
        state.user = { ...state.user, currentTenant: tenantId };
      }
      localStorage.setItem(
        'user',
        JSON.stringify({ ...state.user, currentTenant: tenantId })
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { user, token, expiry, tenants } = action.payload;
        if (user) {
          state.isAuthenticated = true;
          state.user = { ...user, tenants, expiry, token };
          localStorage.setItem('user', JSON.stringify(state.user));
        }
        state.error = null;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout, addTenant } = authSlice.actions;
export default authSlice.reducer;
