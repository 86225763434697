import React from 'react';

type IconDocumentProps = {
  color?: string;
  width?: string;
  height?: string;
};
const IconDocument: React.FC<IconDocumentProps> = ({
  color = 'black',
  width = '20',
  height = '27',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="document-icon" clip-path="url(#clip0_516_6254)">
        <path
          id="Vector"
          d="M0.833333 25.8333V0.833333H10.8333V8.75V9.16667H11.25H19.1667V25.8333H0.833333ZM11.6667 1.17708L18.8229 8.33333H11.6667V1.17708ZM11.6667 0H0.833333H0V0.833333V25.8333V26.6667H0.833333H19.1667H20V25.8333V8.33333L11.6667 0ZM3.33333 3.33333V4.16667H3.75H7.91667H8.33333V3.33333H7.91667H3.75H3.33333ZM3.33333 6.66667V7.5H3.75H7.91667H8.33333V6.66667H7.91667H3.75H3.33333ZM11.6667 22.5V23.3333H12.0833H16.25H16.6667V22.5H16.25H12.0833H11.6667ZM15.8333 12.5V17.5H4.16667V12.5H15.8333ZM4.16667 11.6667H3.33333V12.5V17.5V18.3333H4.16667H15.8333H16.6667V17.5V12.5V11.6667H15.8333H4.16667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_516_6254">
          <rect width={width} height="26.6667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDocument;
