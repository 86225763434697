import cx from 'clsx';

import { Textarea as MantineTextarea, TextareaProps } from '@mantine/core';

import classes from './Textarea.module.scss';

interface CustomTextareaProps extends TextareaProps {
  showAsComment?: boolean;
}

const Textarea: React.FC<CustomTextareaProps> = ({
  showAsComment = false,
  ...props
}) => {
  return (
    <MantineTextarea
      classNames={{
        input: cx({ [classes.textAreaInput]: showAsComment }),
        section: cx({ [classes.textAreaSection]: showAsComment }),
      }}
      {...props}
    />
  );
};

export default Textarea;
