import React from 'react';

import {
  Box,
  Text,
  Checkbox,
  Group,
  Stack,
  Divider,
  Spoiler,
  Center,
} from '@mantine/core';
import { RuleType, RuleWithEvalType, ruleConfig } from 'Types/ruleTypes';

import SkeletonItem from './RuleSkeleton';

interface ListRulesProps {
  rules: RuleType[];
  loading: boolean;
  selectedRules: RuleType[];
  onRuleSelect: (rule: ruleConfig) => void;
}

const ListRules: React.FC<ListRulesProps> = ({
  loading,
  rules,
  selectedRules,
  onRuleSelect,
}) => {
  return (
    <Box mb={16} style={{ overflowY: 'scroll' }} w={'100%'} pos="relative">
      <SkeletonItem loading={loading} />
      {!loading && (
        <Stack gap="xs">
          {rules.map((item: RuleWithEvalType) => {
            const alreadySelected = selectedRules
              .map((item) => item.id)
              .includes(item.id);
            return (
              <React.Fragment key={item.id}>
                <Group
                  wrap="nowrap"
                  align="flex-start"
                  style={{
                    pointerEvents: item?.hasRuleEval ? 'none' : 'inherit',
                    opacity: item?.hasRuleEval ? 0.5 : 'inherit',
                  }}
                >
                  <Checkbox
                    checked={alreadySelected}
                    mt={4}
                    onClick={() => onRuleSelect({ ruleToAdd: item })}
                  />
                  <Box>
                    <Text size="sm" fw={400}>
                      {item.title}
                    </Text>
                    <Spoiler
                      maxHeight={50}
                      showLabel={
                        <Text c="blue" size="sm">
                          Show more
                        </Text>
                      }
                      hideLabel={
                        <Text c="blue" size="sm">
                          Show less
                        </Text>
                      }
                      fz={12}
                    >
                      {item.description}
                    </Spoiler>
                  </Box>
                </Group>
                <Divider mb={12} mt={8} />
              </React.Fragment>
            );
          })}
        </Stack>
      )}
      {!loading && rules.length === 0 && (
        <Center>
          <Text size="sm">No Rules to show</Text>
        </Center>
      )}
    </Box>
  );
};

export default ListRules;
