import React from 'react';

import { WebViewerInstance } from '@pdftron/webviewer';

const useTextHighlight = (
  webViewerInstance: WebViewerInstance | null,
  textCoords: any
) => {
  // Create a ref to store the last added highlight to avoid conflicts with user annotations
  const lastHighlightRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!webViewerInstance) return;

    const { documentViewer } = webViewerInstance.Core;

    if (!textCoords) {
      if (lastHighlightRef.current) {
        const annotManager = documentViewer.getAnnotationManager();
        annotManager.deleteAnnotation(lastHighlightRef.current);
        lastHighlightRef.current = null;
      }
      return;
    }

    if (!documentViewer.getDocument()) return;

    try {
      // Dynamically scroll to the page with the text selected
      if (documentViewer.getCurrentPage() !== textCoords.page) {
        documentViewer.setCurrentPage(textCoords.page, true);
      }

      // TODO: Improve the highlight to cover the entire word or selection
      // Current implementation may not fully cover taller characters or multi-line selections
      const quad = new webViewerInstance.Core.Math.Quad(
        textCoords.x_left,
        textCoords.y_top - 8,
        textCoords.x_right,
        textCoords.y_top - 8,
        textCoords.x_right,
        textCoords.y_top + 2,
        textCoords.x_left,
        textCoords.y_top + 2
      );

      const highlight =
        new webViewerInstance.Core.Annotations.TextHighlightAnnotation();
      highlight.PageNumber = textCoords.page;
      highlight.Quads = [quad];
      highlight.StrokeColor = new webViewerInstance.Core.Annotations.Color(
        255,
        255,
        0
      ); // Yellow highlight

      const annotManager = documentViewer.getAnnotationManager();

      // Remove the previous highlight if it exists
      if (lastHighlightRef.current) {
        annotManager.deleteAnnotation(lastHighlightRef.current);
      }

      // Mark the annotation as imported so it won't be sent to the database
      annotManager.addAnnotation(highlight, { imported: true });
      annotManager.redrawAnnotation(highlight);

      // Store the new highlight as the last added
      lastHighlightRef.current = highlight;

      // Scroll to the annotation after it's been added
      webViewerInstance.Core.documentViewer.displayPageLocation(
        highlight.getPageNumber(),
        0,
        highlight.getRect().y1 - 50 // Subtract offset to position highlight lower in view
      );
    } catch (error) {
      console.error('Error applying text highlight:', error);
    }
  }, [webViewerInstance, textCoords]);
};

export default useTextHighlight;
