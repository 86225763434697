import {
  Select,
  Flex,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Button,
  Box,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconVersions,
  IconStar,
  IconGitCompare,
  IconTrash,
} from '@tabler/icons-react';
import SnapshotService from 'Api/snapshotService';
import { DocumentSnapshotType } from 'Types/docTypes';
import { transformDateString } from 'Utils/transform';

const StarAction = () => (
  <Tooltip label="Mark Latest">
    <ActionIcon variant="transparent">
      <IconStar stroke={1.5} />
    </ActionIcon>
  </Tooltip>
);

const CompareAction: React.FC<{
  optionValue: string;
  showCompareSnapshot: (value: string) => void;
}> = ({ optionValue, showCompareSnapshot }) => (
  <Tooltip label="Compare with latest">
    <ActionIcon
      onClick={(event) => {
        event.stopPropagation();
        showCompareSnapshot(optionValue);
      }}
      variant="transparent"
    >
      <IconGitCompare stroke={1.5} />
    </ActionIcon>
  </Tooltip>
);

const DeleteAction: React.FC<{
  optionValue: string;
  disabled?: boolean;
  callbackOnClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined;
  callbackOnComplete?: (snapshotId: string) => void | (() => void);
}> = ({
  optionValue,
  disabled = false,
  callbackOnComplete,
  callbackOnClick,
}) => {
  const confirmDeleteSnapshot = () => {
    modals.open({
      title: 'Delete Snapshot',
      children: (
        <Box>
          <Text size="sm">
            Are you sure you want to this delete Snapshot? <br />
            This will also remove the Assessment associated with the snapshot.
          </Text>
          <Flex style={{ width: '100%' }} align="center" justify="flex-end">
            <Group gap="sm">
              <Button
                variant="outline"
                size="compact-md"
                onClick={async () => {
                  modals.closeAll();
                  if (optionValue && parseInt(optionValue)) {
                    await SnapshotService.deleteSnapshots(
                      parseInt(optionValue)
                    );
                    if (callbackOnComplete) {
                      callbackOnComplete(optionValue);
                    }
                  }
                }}
              >
                Yes
              </Button>
              <Button
                variant="subtle"
                size="compact-md"
                onClick={() => modals.closeAll()}
              >
                Cancel
              </Button>
            </Group>
          </Flex>
        </Box>
      ),
    });
  };

  return (
    <Tooltip label="Delete Snapshot">
      <ActionIcon
        disabled={disabled}
        variant="transparent"
        onClick={(event) => {
          if (callbackOnClick) callbackOnClick(event);
          confirmDeleteSnapshot();
        }}
      >
        <IconTrash stroke={1.5} />
      </ActionIcon>
    </Tooltip>
  );
};

interface SelectSnapshotVersionsProps {
  snapshotVersions: DocumentSnapshotType[];
  selectedSnapshot: string | undefined;
  setSnapshotVersions: (value: DocumentSnapshotType[]) => void;
  showCompareSnapshot: (value: string) => void;
  setSelectedSnapshot: (value: string) => void;
}

const SelectSnapshotVersions: React.FC<SelectSnapshotVersionsProps> = ({
  snapshotVersions,
  selectedSnapshot,
  showCompareSnapshot,
  setSelectedSnapshot,
  setSnapshotVersions,
}) => {
  const [dropdownOpened, handleDropdown] = useDisclosure();

  const updateSnapshotVersions = (deletedSnapshotId: string) => {
    const updatedSnapshots = snapshotVersions.filter(
      (snapshot) => snapshot.id.toString() !== deletedSnapshotId
    );
    setSnapshotVersions(updatedSnapshots);
  };

  return (
    <Select
      miw={300}
      leftSection={<IconVersions />}
      placeholder="Choose Snapshot"
      onClick={handleDropdown.toggle}
      onBlur={handleDropdown.close}
      dropdownOpened={dropdownOpened}
      data={snapshotVersions.map((snapshot) => ({
        label: transformDateString(snapshot.created_at, true),
        value: snapshot.id.toString(),
      }))}
      allowDeselect={false}
      onChange={(value) => {
        if (!value) return;
        setSelectedSnapshot(value);
        handleDropdown.close();
      }}
      renderOption={({ option, checked }) => (
        <Flex w="100%" justify="space-between">
          <Text size="sm" ml="sm" fw={checked ? 700 : undefined}>
            {option.label}
          </Text>
          {selectedSnapshot && option.value === selectedSnapshot ? null : (
            <Group gap={5}>
              <StarAction />
              <CompareAction
                optionValue={option.value}
                showCompareSnapshot={showCompareSnapshot}
              />
              <DeleteAction
                disabled={
                  option.value === selectedSnapshot || !selectedSnapshot
                }
                optionValue={option.value}
                callbackOnComplete={updateSnapshotVersions}
                callbackOnClick={(event) => {
                  event.stopPropagation();
                  handleDropdown.close();
                }}
              />
            </Group>
          )}
        </Flex>
      )}
      value={selectedSnapshot}
    />
  );
};

export { DeleteAction };
export default SelectSnapshotVersions;
