import React from 'react';

import {
  Group,
  Text,
  Tooltip,
  ActionIcon,
  useMantineTheme,
  Grid,
} from '@mantine/core';
import {
  IconEyeOff,
  IconChevronDown,
  IconChevronRight,
} from '@tabler/icons-react';
import errorMessageIcon from 'Assets/error-message.svg';
import manualRuleIcon from 'Assets/hand-sparkles-thin.svg';
import ruleInprogressIcon from 'Assets/rule-in-progress.svg';
import IconDocument from 'Components/icons/IconDocument';
import { RuleEvalStatusCodes } from 'Constants/index';
import useRuleStatus from 'Src/hooks/useRuleStatus';
import { RuleEvalType } from 'Types/ruleTypes';
import { getRuleEvalStatusColor } from 'Utils/transform';

interface RuleHeaderProps {
  currentRuleEval: RuleEvalType;
  onSelectStatus: (status: string) => void;
  cardOpened: boolean;
  toggleCard: () => void;
}

const DisplayRuleInProgress = () => {
  return (
    <Tooltip label="Rule execution is in prorgess">
      <img src={ruleInprogressIcon} width="20px" height="20px" />
    </Tooltip>
  );
};

const RuleHeader: React.FC<RuleHeaderProps> = ({
  currentRuleEval,
  onSelectStatus,
  cardOpened,
  toggleCard,
}) => {
  const theme = useMantineTheme();
  const { isManualRule, isRuleInProgress, ruleIgnored, errorMessage } =
    useRuleStatus({ ruleEval: currentRuleEval });
  const showRetriggerAction = isRuleInProgress && !errorMessage;

  const onIgnoreRule = () => {
    onSelectStatus(
      ruleIgnored ? RuleEvalStatusCodes.PENDING : RuleEvalStatusCodes.IGNORED
    );
  };

  return (
    <Grid gutter="xs" grow>
      <Grid.Col span={0.8}>
        <Group p="0">
          <IconDocument
            color={getRuleEvalStatusColor(
              theme,
              currentRuleEval.status?.id.toString()
            )}
          />
        </Group>
      </Grid.Col>
      <Grid.Col span={8} onClick={toggleCard} style={{ cursor: 'pointer' }}>
        <Group wrap="nowrap">
          <Text size="sm" lineClamp={!cardOpened ? 1 : undefined}>
            {currentRuleEval.rule.title}
          </Text>
          {isManualRule && (
            <Tooltip label="Manual Rule">
              <img src={manualRuleIcon} alt="Manual rule" />
            </Tooltip>
          )}
        </Group>
      </Grid.Col>
      <Grid.Col span={3}>
        <Group gap="sm" justify="flex-end">
          {!cardOpened && showRetriggerAction && <DisplayRuleInProgress />}
          {errorMessage && (
            <Tooltip label={errorMessage}>
              <img src={errorMessageIcon} alt="Error message" />
            </Tooltip>
          )}
          <Tooltip label={ruleIgnored ? 'Unhide rule' : 'Hide rule'}>
            <ActionIcon
              variant="transparent"
              color="gray"
              size="sm"
              onClick={onIgnoreRule}
            >
              <IconEyeOff
                color={ruleIgnored ? 'black' : theme.colors.gray[4]}
                stroke={1.5}
              />
            </ActionIcon>
          </Tooltip>
          <ActionIcon
            variant="transparent"
            onClick={toggleCard}
            size="sm"
            color="gray"
          >
            {cardOpened ? <IconChevronDown /> : <IconChevronRight />}
          </ActionIcon>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default RuleHeader;
