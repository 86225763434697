const ColorCodeForExecutionStatus = {
  '10': 'orange',
  '20': 'green',
  '30': 'red',
  '40': 'yellow',
  '50': 'gray',
};

const RuleEvalStatus = {
  '10': 'PENDING',
  '20': 'APPROVED',
  '30': 'REJECTED',
  // '40': 'CANCELED',
  // '50': 'IGNORED',
};

const RuleEvalStatusCodes = {
  PENDING: '10',
  APPROVED: '20',
  REJECTED: '30',
  IGNORED: '50',
};

const LayoutVariant = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

const Assessment = {
  MAX_FILE_SIZE: 20, // in Mbs
};

const AssessmentTabs = {
  ASSESSMENT_TAB: 'assessment',
  SETUP_TAB: 'setup',
};

const RuleExecutionStatus = {
  NOT_STARTED: 'not_started',
  QUEUED: 'queued',
  EXECUTING: 'executing',
  IGNORED: 'ignored',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
};

const RuleEvaluationStatus = {
  PENDING: 'Pending',
};

const RULE_TAG_KEY = 'temp_group_name';

export {
  ColorCodeForExecutionStatus,
  LayoutVariant,
  RuleEvalStatus,
  Assessment,
  AssessmentTabs,
  RuleExecutionStatus,
  RuleEvaluationStatus,
  RuleEvalStatusCodes,
  RULE_TAG_KEY,
};
