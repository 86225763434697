import { PageClusterWordMap } from 'Types/extractorTypes';

const CURRENT_SERIALIZATION_VERSION = 'v1';

/*
    Most of the implementation in this file is typescript version of https://github.com/zenafide/extractor/blob/main/extractor/sdk/core.py
    TODO: Should we follow the same naming conventions as the Python SDK for consistency in the documentation ?
*/

/**
 * @internal
 * This class is intended for internal use within this module.
 */
class _BlockTypeRegistry {
  private registry: Record<string, typeof Block> = {};

  register(blockType: string): (cls: typeof Block) => typeof Block {
    return (cls: typeof Block) => {
      if (blockType in this.registry) {
        throw new Error(`Block type ${blockType} already registered.`);
      }

      (cls as any).__block_type__ = blockType;
      this.registry[blockType] = cls;

      return cls;
    };
  }

  getBlockType(blockType: string): typeof Block {
    return this.registry[blockType];
  }
}

const BT_REGISTRY = new _BlockTypeRegistry();

/**
 * @internal
 * This class is intended for internal use within this module.
 */
class _WithContext {
  rootDocument!: Document;

  constructor(kwargs: Record<string, any> = {}) {
    Object.assign(this, kwargs);
  }
}

class FontStyle extends _WithContext {
  static displayName = 'FontStyle';

  font_size!: number;
  font_weight!: number;
  font_name!: string;
  font_family!: string;
  font_color!: string;
  text_decoration!: string[];
  font_style!: string[];
}

class BoundingBox extends _WithContext {
  static displayName = 'BoundingBox';

  x_left!: number;
  y_top!: number;
  x_right!: number;
  y_bottom!: number;

  get width(): number {
    return this.x_right - this.x_left;
  }

  get height(): number {
    return this.y_bottom - this.y_top;
  }

  static fromBlocks(blocks: Block[]): BoundingBox | null {
    if (!blocks.length) return null;

    const blocksWithBbox = blocks.filter((b) => b.bounding_box);

    if (!blocksWithBbox.length) return null;

    const boundingBox = new BoundingBox();
    boundingBox.rootDocument = blocksWithBbox[0].rootDocument;
    boundingBox.x_left = Math.min(
      ...blocksWithBbox.map((b) => b.bounding_box!.x_left)
    );
    boundingBox.y_top = Math.min(
      ...blocksWithBbox.map((b) => b.bounding_box!.y_top)
    );
    boundingBox.x_right = Math.max(
      ...blocksWithBbox.map((b) => b.bounding_box!.x_right)
    );
    boundingBox.y_bottom = Math.max(
      ...blocksWithBbox.map((b) => b.bounding_box!.y_bottom)
    );
    return boundingBox;
  }
}

class Relationship extends _WithContext {
  static displayName = 'Relationship';

  name!: string;
  target_id!: string;

  get target(): Block {
    return this.rootDocument.idToBlock[this.target_id];
  }
}

class Block extends _WithContext {
  static displayName = 'Block';

  __block_type__!: string;
  id!: string;
  rotation: number = 0;
  relationships!: Relationship[];
  page_number!: number;
  bounding_box!: BoundingBox;

  get blockType(): string {
    return this.__block_type__;
  }

  get page(): Page {
    return this.rootDocument.pageNumberToPage[this.page_number];
  }

  getRelatedBlocks(
    recursive: boolean = false,
    filters: Record<string, any> = {}
  ): Block[] {
    return this._getRelatedBlocks(recursive, new Set(), filters);
  }

  private _getRelatedBlocks(
    recursive: boolean,
    seen: Set<Block>,
    filters: Record<string, any>
  ): Block[] {
    if (seen.has(this)) return [];
    seen.add(this);

    const result: Block[] = [];
    if (!this.relationships || this.relationships?.length === 0) return result;
    for (const rel of this.relationships) {
      if (filters.name && filters.name !== rel.name) continue;

      result.push(rel.target);
      if (recursive && rel.target) {
        result.push(...rel.target._getRelatedBlocks(recursive, seen, filters));
      }
    }

    return result;
  }

  getChildBlocks(recursive: boolean = false): Block[] {
    return this.getRelatedBlocks(recursive, { name: 'child' });
  }

  getWords(recursive: boolean = false): (Word & Block)[] {
    return this.getChildBlocks(recursive).filter(
      (c): c is Word => c instanceof Word
    );
  }

  toString(): string {
    return `[${this.blockType}] ${this.id}`;
  }
}

const PageDecorator = BT_REGISTRY.register('page');

class Page extends Block {
  static displayName = 'Page';
}
PageDecorator(Page);

class Document {
  static displayName = 'Document';
  version: string | null = null;
  pages: Page[];
  idToBlock: Record<string, Block> = {};
  pageNumberToPage: Record<number, Page> = {};
  pageClusterWordMap: PageClusterWordMap = {};

  constructor() {
    this.pages = [];
  }

  get blocks(): Block[] {
    const blocks: Block[] = [];
    for (const page of this.pages) {
      blocks.push(page);
      blocks.push(...page.getRelatedBlocks(true));
    }
    return blocks;
  }
}

const WordDecorator = BT_REGISTRY.register('word');

class Word extends Block {
  static displayName = 'Word';

  text!: string;
  fontStyle!: FontStyle;

  toString(): string {
    return `${super.toString()}: ${this.text}`;
  }
}

WordDecorator(Word);

const LineDecorator = BT_REGISTRY.register('line');

class Line extends Block {
  static displayName = 'Line';

  cluster_sequence_id!: number;
  //   bounding_box!: BoundingBox;
  //   page_number!: number;

  constructor(kwargs: Record<string, any> = {}) {
    super(kwargs);
    this.updateBoundingBoxAndPageNumber();
  }

  private updateBoundingBoxAndPageNumber(): void {
    const words = this.getRelatedBlocks(false);
    const bbox = BoundingBox.fromBlocks(words);
    if (bbox) {
      this.bounding_box = bbox;
    }
    if (words.length > 0) {
      this.page_number = words[0].page_number;
    }
  }

  get computedText(): string {
    return this.getWords(true)
      .map((w) => w.text)
      .join(' ');
  }

  toString(): string {
    return `${super.toString()}: ${this.computedText}`;
  }
}

LineDecorator(Line);

const ImageDecorator = BT_REGISTRY.register('image');

class Image extends Block {
  static displayName = 'Image';

  resolution!: [number, number];
  format!: string;
  imageBytes: Uint8Array | null = null;
}

ImageDecorator(Image);

export {
  Document,
  Page,
  Block,
  Word,
  Line,
  Image,
  BT_REGISTRY,
  CURRENT_SERIALIZATION_VERSION,
  BoundingBox,
  Relationship,
  FontStyle,
  _WithContext,
};
