import { debounce } from 'lodash';
import React, { SyntheticEvent, useState, useCallback, useEffect } from 'react';

import {
  Flex,
  Input,
  Box,
  ActionIcon,
  Text,
  Group,
  CloseButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch, IconFilter, IconArrowsSort } from '@tabler/icons-react';
import { FiltersType } from 'Types/commonTypes';
import { RuleType, ruleConfig } from 'Types/ruleTypes';

import FilterContainer from './FilterContainer';

type PropsTypes = {
  onRuleSelect: (config: ruleConfig) => void;
  noOfSelectedRules: number;
  rules: RuleType[];
  allRules: RuleType[];
  fetchRules: (filters?: FiltersType) => void;
  searchRule: (text: string) => void;
};

const SearchWithFilters: React.FC<PropsTypes> = ({
  onRuleSelect,
  rules,
  fetchRules,
  searchRule,
  allRules,
  noOfSelectedRules,
}) => {
  const [filtersOpened, handleFiltersOpened] = useDisclosure(false);
  const [filtersApplied, handleFiltersApplied] = useDisclosure(false);
  const [filters, setFilters] = useState<FiltersType>({});
  const [searchText, setSearchText] = useState<string>('');
  const [selectAll, handleSelectAll] = useDisclosure(true);

  const onSelectAll = () => {
    handleSelectAll.toggle();
    onRuleSelect({ addAll: true });
  };

  const onRemoveAll = () => {
    handleSelectAll.toggle();
    onRuleSelect({ removeAll: true });
  };

  const applyFilters = () => {
    handleFiltersOpened.toggle();
    handleFiltersApplied.toggle();
    fetchRules(filters);
  };

  const onClearFilters = () => {
    setFilters({});
    fetchRules();
    handleFiltersOpened.close();
    handleFiltersApplied.close();
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const debouncedSearch = useCallback(debounce(searchRule, 500), []);

  const onSearch = async (e: SyntheticEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchText(value);
    debouncedSearch(value);
  };

  const onClearSearch = () => {
    setSearchText('');
    fetchRules();
  };

  useEffect(() => {
    setFilters({});
  }, []);
  return (
    <Box py={8} w={'100%'}>
      {/* Search and other action items */}
      <Flex justify={'space-between'} w={'100%'} align={'center'}>
        <Input
          size="sm"
          placeholder="Search.."
          leftSection={<IconSearch size={18} />}
          w={'85%'}
          onChange={onSearch}
          value={searchText}
          rightSectionPointerEvents="all"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => onClearSearch()}
              style={{ display: searchText ? undefined : 'none' }}
            />
          }
        />
        <ActionIcon
          size={'input-sm'}
          variant={filtersOpened ? 'primary"' : 'default'}
          onClick={() => handleFiltersOpened.toggle()}
        >
          <IconFilter stroke={1} />
        </ActionIcon>
        <ActionIcon size={'input-sm'} variant="default" disabled>
          <IconArrowsSort stroke={1} />
        </ActionIcon>
      </Flex>
      <FilterContainer
        filtersOpened={filtersOpened}
        onClearFilters={onClearFilters}
        filters={filters}
        setFilters={setFilters}
        applyFilters={applyFilters}
        handleFiltersOpened={handleFiltersOpened}
        rules={rules}
      />
      {/* Actions related selecting/de-selcting the rules */}
      <Flex
        mt={8}
        justify={'space-between'}
        style={{ transition: '0.5s ease-out' }}
      >
        <Text
          size="xs"
          fw={700}
          c={selectAll ? '#009AAF' : '#DA0404'}
          style={{ cursor: 'pointer' }}
          onClick={() => (selectAll ? onSelectAll() : onRemoveAll())}
        >
          {selectAll ? 'Select all' : 'Remove all'}
        </Text>
        <Box>
          {filtersApplied ? (
            <Text
              size="xs"
              fw={700}
              c="#DA0404"
              style={{ cursor: 'pointer' }}
              onClick={onClearFilters}
            >
              See all rules
            </Text>
          ) : (
            <Group>
              <Text size="xs">{`${noOfSelectedRules} / ${allRules.length} selected`}</Text>
              {/* {noOfSelectedRules && (
                <Text
                  size="xs"
                  fw={700}
                  c="#DA0404"
                  style={{ cursor: 'pointer' }}
                  onClick={onRemoveAll}
                >
                  Remove all
                </Text>
              )} */}
            </Group>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default SearchWithFilters;
