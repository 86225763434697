import { useEffect } from 'react';

import { WebViewerInstance, Core } from '@pdftron/webviewer';
import AnnotationService from 'Api/annotationService';
import { SnapshotAnnotationType } from 'Types/docTypes';
import { AnnotationConfigTypes, FileViwerMode } from 'Types/fileViewerTypes';
import { showErrorNotification } from 'Utils/notifications';

type PropTypes = {
  instance: WebViewerInstance | null;
  annotationConfig: AnnotationConfigTypes | undefined;
  mode?: FileViwerMode;
};

enum AnnotationAction {
  MODIFY = 'modify',
  ADD = 'add',
  DELETE = 'delete',
}

type AnnotationType = Core.Annotations.Annotation & { Id: string };

const useSnapshotAnnotations = ({
  instance,
  annotationConfig,
  mode,
}: PropTypes) => {
  const {
    displayAnnotations = true,
    documentSnaphostId,
    enableAnnotationEditing = true,
    assessmentId,
  } = annotationConfig || {};

  useEffect(() => {
    if (!instance || !documentSnaphostId) return;
    const { annotationManager } = instance.Core;
    const annotationIds = new Map<string, number>();

    const renderAnnotations = async () => {
      try {
        const response = await AnnotationService.getAnnotations({
          filters: { document_snapshot: documentSnaphostId },
        });
        const { results = [] } = response.data || {};
        results.forEach(async (item: SnapshotAnnotationType) => {
          const annotations = await annotationManager.importAnnotations(
            item.annotation_xml
          );
          annotations.forEach((annotation: AnnotationType) => {
            const annotationId = annotation.Id;
            if (annotationId) {
              annotationIds.set(annotationId, item.id);
              annotationManager.redrawAnnotation(annotation);
            }
          });
        });
      } catch (e) {
        showErrorNotification('Error fetching annotations');
      }
    };

    const onAnnotationChange = async (
      annotations: Core.Annotations.Annotation[],
      action: AnnotationAction,
      { imported }: { imported: boolean }
    ) => {
      if (imported) return;

      /*
        Note:
        - To handle CRUD operations at the document level, we are currently using `exportAnnotations`. 
          This approach provides flexibility in managing individual annotations and offers more options. 
          For more details, refer to: https://docs.apryse.com/api/web/Core.AnnotationManager.html#exportAnnotations

        - If batch operations and real-time updates are required, `exportAnnotationCommand` is a better choice. 
          However, this method is based on change events (add, modify) and does not support individual operations. 
          For more details, refer to: https://docs.apryse.com/api/web/Core.AnnotationManager.html#exportAnnotationCommand
      */
      try {
        if ([AnnotationAction.MODIFY, AnnotationAction.ADD].includes(action)) {
          for (const annotation of annotations) {
            const identifier: string = annotation.Id;
            const xfdfString = await annotationManager.exportAnnotations({
              annotList: [annotation],
            });
            const requestData = {
              annotation_xml: xfdfString,
              document_snapshot: documentSnaphostId,
              assessment: assessmentId,
              rule_eval: null,
              rule_execution: null,
              comment_thread: null,
              comment_thread_post: null,
            };
            if (action === AnnotationAction.ADD) {
              const response =
                await AnnotationService.createAnnotations(requestData);
              annotationIds.set(identifier, response.data.id);
            } else {
              const annotationId = annotationIds.get(identifier);
              if (annotationId)
                await AnnotationService.updateAnnotations(
                  annotationId,
                  requestData
                );
            }
          }
        } else if (action === AnnotationAction.DELETE) {
          for (const annotation of annotations) {
            const identifier: string = annotation.Id;
            const annotationId = annotationIds.get(identifier);
            if (annotationId) {
              await AnnotationService.deleteAnnotations(annotationId);
            }
            annotationIds.delete(identifier);
          }
        }
      } catch (error) {
        showErrorNotification('Error updating/creating annotations');
      }
    };

    if (mode === FileViwerMode.VIEW) {
      renderAnnotations();
      annotationManager.enableReadOnlyMode();
    } else {
      if (displayAnnotations) {
        renderAnnotations();
      }
      if (enableAnnotationEditing) {
        annotationManager.addEventListener(
          'annotationChanged',
          onAnnotationChange
        );
      }
    }

    return () => {
      if (instance) {
        instance.Core.annotationManager.removeEventListener(
          'annotationChanged',
          onAnnotationChange
        );
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [instance]);
};

export default useSnapshotAnnotations;
