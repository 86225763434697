import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Flex, Indicator, Text, Tabs, Card } from '@mantine/core';
import ChatWindow from 'Components/chat-window/ChatWindow';
import TxtFileViewer from 'Components/txt-file-viewer/TxtFileViewer';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { MessageTypes } from 'Types/chatTypes';
import { DocumentDataType } from 'Types/docTypes';
import { TextCoords } from 'Types/fileViewerTypes';

import AssessmentOverview from './overview/AssessmentOverview';

type AssessmentTabProps = {
  assessmentData: AssessmentDetailType | null;
  documentData: DocumentDataType | null;
  setAssessmentData: Dispatch<SetStateAction<AssessmentDetailType | null>>;
  setTextCoords: Dispatch<SetStateAction<TextCoords | null>>;
  reRunAssessment: (data: AssessmentDetailType) => void;
  fetchDocument: () => Promise<DocumentDataType | null>;
  changeMainTab: (tab: string) => void;
};

const AssessmentTab: React.FC<AssessmentTabProps> = ({
  documentData,
  assessmentData,
  setTextCoords,
  reRunAssessment,
  changeMainTab,
  ...rest
}) => {
  const [activeMainTab, setActiveMainTab] = useState('assessment');
  const [copilotMessages, setCopilotMessages] = useState<MessageTypes[]>([]);

  const mainTabs = [
    { label: 'Rule Evaluation', value: 'assessment' },
    { label: 'Text Extract', value: 'extract' },
    {
      label: (
        <Indicator position="middle-start" processing color="lime" size={12}>
          <Box ml="sm">Co-Pilot</Box>
        </Indicator>
      ),
      value: 'copilot',
    },
  ];

  const handleTabChange = (value: string | null) => {
    if (value !== null) {
      setActiveMainTab(value);
    }
  };

  return (
    <Tabs
      variant="pills"
      defaultValue="setup"
      value={activeMainTab}
      onChange={handleTabChange}
      keepMounted={false}
    >
      <Flex direction="column" w={'100%'}>
        <Box
          w={'100%'}
          pos={'sticky'}
          top={'0'}
          bg="white"
          style={{ zIndex: 99 }}
          mih="83vh"
        >
          <Card
            p={8}
            withBorder
            radius={0}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Tabs.List>
              {mainTabs.map((tab, index) => {
                return (
                  <Tabs.Tab
                    style={{ backroundColor: 'none' }}
                    color="white"
                    value={tab.value}
                    key={index}
                  >
                    <Text
                      c="black"
                      size="sm"
                      fw={activeMainTab === tab.value ? 700 : 500}
                    >
                      {tab.label}
                    </Text>
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
          </Card>

          <Flex flex={1} w={'100%'}>
            <Tabs.Panel value="assessment" w="100%">
              <AssessmentOverview
                {...rest}
                assessmentData={assessmentData}
                documentData={documentData}
                reRunAssessment={reRunAssessment}
                changeMainTab={changeMainTab}
              />
            </Tabs.Panel>
            <Tabs.Panel value="copilot" w="100%">
              <ChatWindow
                documentData={documentData}
                setCopilotMessages={setCopilotMessages}
                copilotMessages={copilotMessages}
              />
            </Tabs.Panel>
            <Tabs.Panel value="extract" w="100%">
              <TxtFileViewer
                url={documentData?.latest_snapshot?.text_file_link}
                setTextCoords={setTextCoords}
              />
            </Tabs.Panel>
          </Flex>
        </Box>
      </Flex>
    </Tabs>
  );
};

export default AssessmentTab;
